import { useCustom } from '@pankod/refine-core';
import { TSelectResourceType } from '../components/core/HMISSelect';

interface TSelectType {
  label: string;
  value: string;
}

export const useGetOptions = (
  resource: TSelectResourceType,
  filter?: string | number,
) => {
  const { data } = useCustom<TSelectType[]>({
    url: `search`,
    method: 'get',
    config: {
      query: {
        module: resource.module || '',
        type: resource.submodule || '',
        filter: filter || null,
      },
    },
  });
  return data?.data;
};
