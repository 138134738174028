import { ResourceProps } from '@pankod/refine-core';

import { PatientReport } from './list';
export const patientReportResource: ResourceProps = {
  name: 'patients/report',
  parentName: 'patient',
  options: {
    route: 'reports',
    label: 'Report',
  },
  list: PatientReport,
};
