import { ResourceProps } from '@pankod/refine-core';
import { MedicationDispenseCreate } from './create';
import { MedicationDispenseList } from './list';
import { MedicationDispenseShow } from './show';
import { MedicationDispenseEdit } from './edit';

export const medicationDispenseResource: ResourceProps = {
  name: 'dispense-medications',
  parentName: 'medication',
  options: {
    route: 'dispense',
    label: 'Dispense',
  },
  create: MedicationDispenseCreate,
  list: MedicationDispenseList,
  show: MedicationDispenseShow,
  edit: MedicationDispenseEdit,
};
