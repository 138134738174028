import { IResourceComponentsProps } from '@pankod/refine-core';

import { HMISDatePicker } from '../../../components/core/HMISDatePicker';
import {
  Checkbox,
  Col,
  Edit,
  Form,
  Input,
  Row,
  Select,
  useForm,
} from '@pankod/refine-antd';
import { HMISSelect } from '../../../components/core/HMISSelect';

export const ClosedIncidentEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Date of Incident'
              name='incidentDate'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISDatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label='Department'
              name='department'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                resource={{ module: 'lookup', submodule: 'departments' }}
              />
            </Form.Item>
            <Form.Item
              label='Incident Reported To'
              name='reportedTo'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Category'
              name='category'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                resource={{ module: 'lookup', submodule: 'incident-category' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Patient Impacted'
              name='patientImpacted'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Status'
              name='status'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: 'Reported',
                    value: 'Reported',
                  },
                  {
                    label: 'Closed',
                    value: 'Closed',
                  },
                  {
                    label: 'Active',
                    value: 'Active',
                  },
                  {
                    label: 'Follow Up',
                    value: 'Follow Up',
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label='Incident Description'
              name='incidentDescription'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Category Item'
              name='categoryItem'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                resource={{ module: 'lookup', submodule: 'category-item' }}
              />
            </Form.Item>
          </Col>
          <Form.Item
            label='Sentinel Event'
            name='isSentinel'
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Checkbox />
          </Form.Item>
        </Row>
      </Form>
    </Edit>
  );
};
