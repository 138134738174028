import { useShow, IResourceComponentsProps } from '@pankod/refine-core';

import { Show, DateField, Typography } from '@pankod/refine-antd';
import { useBarcode } from 'next-barcode';

const { Title, Text } = Typography;

export const InventoryItemsShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const { inputRef } = useBarcode({
    value: record?.name as string,
    options: {
      height: 40,
      width: 1,
    },
  });

  return (
    <Show
      title={
        <>
          {record?.name} / {record?.category}
        </>
      }
      isLoading={isLoading}
    >
      <svg ref={inputRef} />
      <Title level={5}>Name</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>Type</Title>
      <Text>{record?.type}</Text>

      <Title level={5}>Received Date</Title>
      <Text>{<DateField format='LLL' value={record?.receivedDate} />}</Text>
      <Title level={5}>Expiry Date</Title>
      <Text>{<DateField format='LLL' value={record?.expireDate} />}</Text>

      <Title level={5}>Purchase Price</Title>
      <Text>{record?.purchasePrice}</Text>

      <Title level={5}>Sale Price</Title>
      <Text>{record?.salePrice}</Text>

      <Title level={5}>Vendor</Title>
      <Text>{record?.vendor}</Text>

      <Title level={5}>Description</Title>
      <Text>{record?.description}</Text>
    </Show>
  );
};
