import { useTable, List, Table } from '@pankod/refine-antd';

// types (TODO: remove these in favor of global types for main server types)
import { TImaging } from '../../../types';

export const ImagingCompletedList = () => {
  const { tableProps } = useTable<TImaging[]>();
  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column
          dataIndex='dateRequested'
          title='Date Requested'
          render={(_, record: TImaging) => {
            return <span>{record.createdAt}</span>;
          }}
        />
        <Table.Column
          dataIndex='dateCompleted'
          title='Date Completed'
          render={(_, record: TImaging) => {
            return <span>{record.completedAt}</span>;
          }}
        />
        <Table.Column
          dataIndex='patient'
          title='Patient'
          render={(_, record: TImaging) => {
            return <span>{record.patient.firstName}</span>;
          }}
        />
        <Table.Column
          dataIndex='requestedBy'
          title='Requested By'
          render={(_, record: TImaging) => {
            return <span>{record.requestedBy}</span>;
          }}
        />
        <Table.Column
          dataIndex='imagingType'
          title='Imaging Type'
          render={(_, record: TImaging) => {
            return <span>{record.type}</span>;
          }}
        />
        <Table.Column
          dataIndex='results'
          title='Results'
          render={(_, record: TImaging) => {
            return <span>{record.result}</span>;
          }}
        />

        <Table.Column
          dataIndex='notes'
          title='Notes'
          render={(_, record: TImaging) => {
            return <span>{record.note}</span>;
          }}
        />
      </Table>
    </List>
  );
};
