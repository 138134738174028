import axios from 'axios';
import { env } from '../config';

const backendApi = axios.create({
  baseURL: env.BACKEND_URL,
});

// [TODO]: Handle un-wrapping of the backend api response
backendApi.interceptors.response.use((response) => {
  if (response.status !== 200) return response;

  const wrapped = response.data;
  const isTabularData = 'results' in wrapped.data;

  /**
   * Normally mutating arguements is considered bad. We are only doing it here
   * for a reason such that our backend API remains sharable to other platforms
   */
  if (isTabularData) {
    response.data = {
      data: wrapped.data.results,
    };
  } else {
    response.data = wrapped.data;
  }

  return response;
});

export { backendApi };
