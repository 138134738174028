import React from 'react';
import ReactDOM from 'react-dom/client';
import HospitalManagementSystemApplication from './App';

// ant design stylesheets
// import '@pankod/refine-antd/dist/styles.min.css';

import './providers/i18nProvider/i18n';
import { env } from './config';
import { Spin } from '@pankod/refine-antd';

async function createApplication() {
  // Mock Service Worker initialization
  // Only in development mode
  if (env.NODE_ENV !== 'production') {
    try {
      const { worker } = await import('./mocks/browser');
      worker.start();
    } catch (err) {
      console.error('[FAILED]: Failed to start mock service worker');
    }
  }

  // Create the root application
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <React.Suspense
        fallback={
          <Spin
            size='large'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }}
          />
        }
      >
        <HospitalManagementSystemApplication />
      </React.Suspense>
    </React.StrictMode>,
  );
}

createApplication();
