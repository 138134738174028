import { AuditLogProvider } from '@pankod/refine-core';

const HMISAuditLogProvider: AuditLogProvider = {
  async get(params) {
    return Promise.resolve([]);
  },
  async create(params) {
    return Promise.resolve();
  },
  async update(params) {
    return Promise.resolve();
  },
};

export { HMISAuditLogProvider };
