import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  List,
  Table,
  Space,
  EditButton,
  DeleteButton,
  useTable,
  Button,
} from '@pankod/refine-antd';
import { TPatient } from '../../../types';
import { useHandleDischarge } from 'src/services/visit.service';

export const PatientOutPatientsList: React.FC<
  IResourceComponentsProps
> = () => {
  const { tableProps } = useTable<TPatient[]>();
  const discharge = useHandleDischarge();

  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='ID' />
        <Table.Column
          dataIndex='date'
          title='Date'
          render={(_, record: TPatient) => {
            return <span>{record.date}</span>;
          }}
        />
        <Table.Column
          dataIndex='name'
          title='Name'
          render={(_, record: TPatient) => {
            return (
              <span>
                {record.firstName} {record.lastName}
              </span>
            );
          }}
        />

        <Table.Column
          dataIndex='gender'
          title='Gender'
          render={(_, record: TPatient) => {
            return <span>{record.gender}</span>;
          }}
        />
        <Table.Column
          dataIndex='address'
          title='Address'
          render={(_, record: TPatient) => {
            return (
              <span>
                {record.firstName} {record.lastName}
              </span>
            );
          }}
        />

        <Table.Column
          dataIndex='status'
          title='Patients Status'
          render={(_, record: TPatient) => {
            return <span>{record.status}</span>;
          }}
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          render={(_, record: any) => (
            <Space>
              <EditButton
                hideText
                size='small'
                resourceNameOrRouteName='patients/requests'
                recordItemId={record.id}
              />
              <DeleteButton
                hideText
                size='small'
                resourceNameOrRouteName='patients/requests'
                recordItemId={record.id}
              />
              <Button type='primary' onClick={() => discharge(record.id)}>
                Discharge
              </Button>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
