import {
  useTable,
  List,
  Table,
  Button,
  DateField,
  DeleteButton,
  EditButton,
  Space,
  ShowButton,
} from '@pankod/refine-antd';
import { useHandleDischarge } from 'src/services/visit.service';

// types (TODO: remove these in favor of global types for main server types)
import { TPatient } from '../../../types';

export const PaitentAdmitPatientsList = () => {
  const { tableProps } = useTable<TPatient[]>();
  const discharge = useHandleDischarge();

  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='ID' />

        <Table.Column
          dataIndex='firstName'
          title='First Name'
          render={(_, record: TPatient) => {
            return <span>{record.firstName}</span>;
          }}
        />
        <Table.Column
          dataIndex='lastName'
          title='Last Name'
          render={(_, record: TPatient) => {
            return <span>{record.lastName}</span>;
          }}
        />
        <Table.Column
          dataIndex='gender'
          title='Sex'
          render={(_, record: TPatient) => {
            return <span>{record.gender}</span>;
          }}
        />
        <Table.Column
          dataIndex='dateOfBirth'
          title='DOB'
          render={(_, record: TPatient) => {
            return (
              <span>
                <DateField format='LL' value={record?.dateOfBirth} />
              </span>
            );
          }}
        />

        <Table.Column
          dataIndex='status'
          title='Status'
          render={(_, record: TPatient) => {
            return <span>{record.status}</span>;
          }}
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          render={(_, record: any) => (
            <Space>
              <EditButton
                hideText
                size='small'
                resourceNameOrRouteName='patients/requests'
                recordItemId={record.id}
              />
              <ShowButton
                hideText
                size='small'
                resourceNameOrRouteName='patients/requests'
                recordItemId={record.id}
              />
              <Button type='primary' onClick={() => discharge(record.id)}>
                Discharge
              </Button>
              <DeleteButton
                hideText
                size='small'
                resourceNameOrRouteName='patients/requests'
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
