import { ResourceProps } from '@pankod/refine-core';

import { ClosedIncidentList } from './list';
import { ClosedIncidentShow } from './show';
import { ClosedIncidentEdit } from './edit';

export const IncidentClosingResource: ResourceProps = {
  name: 'incidents/closed',
  parentName: 'incidents',
  options: {
    route: 'incidents/closed',
    label: 'Closed',
  },
  list: ClosedIncidentList,
  show: ClosedIncidentShow,
  edit: ClosedIncidentEdit,
};
