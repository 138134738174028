import { inventoryResources } from './pages/inventory';
import { inventoryItemResources } from './pages/inventory/items';
import { inventoryReceivedResources } from './pages/inventory/received';
import { inventoryRequestsResources } from './pages/inventory/requests';
import { ImagingResources } from './pages/imaging';
import { IncidentResources } from './pages/incident/index';
import { patientResource } from './pages/patients';
import { patientListResource } from './pages/patients/Patient List';
import { patientEmergencyPatientsResource } from './pages/patients/Emergency Patients';
import { paitentAdmitPatientsResource } from './pages/patients/admitted patients';
import { patientOutPatientsResource } from './pages/patients/Outpatients';
import { patientReportResource } from './pages/patients/Reports';
import { medicationResources } from './pages/medication';
import { medicationNewRequestResource } from './pages/medication/new request';
import { medicationDispenseResource } from './pages/medication/Dispense';
import { returnMedicationResource } from './pages/medication/return medication';
import { medicationCompletedResource } from './pages/medication/Completed';
import { labsResources } from './pages/labs';
import { administrationResources } from './pages/Administration';
import { administrationNewUserResource } from './pages/Administration/New User';
import { administrationUsersListResource } from './pages/Administration/Users';
import { labsNewRequestResource } from './pages/labs/request';
import { labsCompletedResource } from './pages/labs/Completed';
import { billingResources } from './pages/billing';
import { billingNewInvoiceResource } from './pages/billing/invoice';
import { billingCashierResource } from './pages/billing/Cashier';
import { billingPricesResource } from './pages/billing/Prices';
import { billingPriceProfileResource } from './pages/billing/Price Profiles';
import { lookupResource } from './pages/lookup';
import { ImagingCompletedResource } from './pages/imaging/completed';
import { ImagingRequestResource } from './pages/imaging/requests';
import { IncidentClosingResource } from './pages/incident/closed';
import { NewIncidentResource } from './pages/incident/create-incident/index';

// [TODO]: Add all the refine resources
export const resources = [
  patientResource,
  patientOutPatientsResource,
  patientReportResource,
  inventoryResources,
  inventoryItemResources,
  inventoryReceivedResources,
  inventoryRequestsResources,
  medicationResources,
  medicationNewRequestResource,
  medicationDispenseResource,
  returnMedicationResource,
  medicationCompletedResource,
  ImagingResources,
  IncidentResources,
  labsResources,
  labsNewRequestResource,
  labsCompletedResource,
  administrationResources,
  administrationNewUserResource,
  administrationUsersListResource,
  billingResources,
  billingNewInvoiceResource,
  billingCashierResource,
  billingPricesResource,
  billingPriceProfileResource,
  lookupResource,
  ImagingCompletedResource,
  IncidentClosingResource,
  ImagingRequestResource,
  NewIncidentResource,
  paitentAdmitPatientsResource,
  patientEmergencyPatientsResource,
  patientListResource,
];
