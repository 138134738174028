import {
  useTable,
  List,
  Table,
  EditButton,
  ShowButton,
  DeleteButton,
  Space,
} from '@pankod/refine-antd';

// types (TODO: remove these in favor of global types for main server types)
import { TLabs } from '../../../types';

export const LabsNewRequestList = () => {
  const { tableProps } = useTable<TLabs[]>();
  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column
          dataIndex='paitent'
          title='Paitent'
          render={(_, record: TLabs) => {
            return (
              <span>
                {record.patient.firstName} {record.patient.middleName}{' '}
                {record.patient.lastName} - {record.patient.id}
              </span>
            );
          }}
        />
        <Table.Column
          dataIndex='requestedBy'
          title='Requested By'
          render={(_, record: TLabs) => {
            return <span>{record.requestedBy}</span>;
          }}
        />
        <Table.Column
          dataIndex='notes'
          title='Notes'
          render={(_, record: TLabs) => {
            return <span>{record.note}</span>;
          }}
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          render={(_, record: any) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <ShowButton hideText size='small' recordItemId={record.id} />
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
