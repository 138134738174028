import { AuthProvider } from '@pankod/refine-core';
import { backendApi } from '../utils/axios';

// [TODO]: Add authentication system
const hmisAuthProvider: AuthProvider = {
  login: async ({ email, password }) => {
    await backendApi.post(
      '/auth/login',
      {
        email,
        password,
      },
      {
        withCredentials: true,
      },
    );
  },
  logout: async () => {
    await backendApi.get('/auth/logout', { withCredentials: true });
  },
  checkError: () => Promise.resolve(),
  checkAuth: async () => {
    await backendApi.get('/auth/check-auth', {
      withCredentials: true,
    });
  },
  register: async ({ email, password }) => {
    const res = await backendApi.post(
      '/auth/signup',
      {
        email,
        password,
      },
      {
        withCredentials: true,
      },
    );
    if (res.status === 201) {
      return Promise.resolve();
    }
    return Promise.resolve(false);
  },
  getPermissions: () => Promise.resolve([]),
};

export { hmisAuthProvider };
