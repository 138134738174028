import {
  useTable,
  List,
  Table,
  EditButton,
  ShowButton,
  DeleteButton,
  Space,
} from '@pankod/refine-antd';

// types (TODO: remove these in favor of global types for main server types)
import { TImaging } from '../../../types';

export const ImagingList = () => {
  const { tableProps } = useTable<TImaging[]>();
  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='id' />
        <Table.Column
          dataIndex='name'
          title='Name'
          render={(_, record: TImaging) => {
            return (
              <span>
                {record.patient.firstName} {record.patient.lastName}
              </span>
            );
          }}
        />
        <Table.Column
          dataIndex='name'
          title='Name'
          render={(_, record: TImaging) => {
            return <span>{record.type}</span>;
          }}
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          render={(_, record: any) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <ShowButton hideText size='small' recordItemId={record.id} />
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
