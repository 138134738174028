import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  Col,
  Edit,
  Form,
  Input,
  InputNumber,
  Row,
  useForm,
} from '@pankod/refine-antd';
import { HMISSelect } from '../../../components/core/HMISSelect';
import { HMISDatePicker } from '../../../components/core/HMISDatePicker';

export const InventoryItemsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Item Name'
              name='name'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Sale Pricee'
              name='salePrice'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Date Received'
              name='recieveDate'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISDatePicker
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Vendor'
              name='vendor'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                resource={{ module: 'lookup', submodule: 'vendors' }}
              />
            </Form.Item>
            <Form.Item
              label='Type'
              name='type'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                resource={{ module: 'lookup', submodule: 'item-type' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Category'
              name='category'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                resource={{ module: 'lookup', submodule: 'item-category' }}
              />
            </Form.Item>
            <Form.Item
              label='Purchase Price'
              name='purchasePrice'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Expiration Date'
              name='expireDate'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISDatePicker
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Description'
              name='description'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
