import { ResourceProps } from '@pankod/refine-core';

import { PatientEmergencyPatientsList } from './list';

export const patientEmergencyPatientsResource: ResourceProps = {
  name: 'patients/visit/emergency',
  parentName: 'patient',
  options: {
    route: 'emergency-patients',
    label: 'Emergency Patients',
  },
  list: PatientEmergencyPatientsList,
};
