import { ResourceProps } from '@pankod/refine-core';

import { ImagingCompletedList } from './list';

export const ImagingCompletedResource: ResourceProps = {
  name: 'imagings/completed',
  parentName: 'imagings',
  options: {
    route: 'completed',
    label: 'Completed',
  },
  list: ImagingCompletedList,
};
