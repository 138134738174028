import {
  useTable,
  List,
  Table,
  EditButton,
  ShowButton,
  DeleteButton,
  Space,
} from '@pankod/refine-antd';

// types (TODO: remove these in favor of global types for main server types)

export const BillingPriceProfileList = () => {
  const { tableProps } = useTable<any[]>();
  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='id' />
        <Table.Column
          dataIndex='name'
          title='Name'
          render={(_, record: any) => {
            return <span>{record.name}</span>;
          }}
        />
        <Table.Column
          dataIndex='Discount Percentage'
          title='discountPercentage'
          render={(_, record: any) => {
            return <span>{record.discountPercentage}</span>;
          }}
        />
        <Table.Column
          dataIndex='discountAmount'
          title='Discount Amount'
          render={(_, record: any) => {
            return <span>{record.discountAmount}</span>;
          }}
        />
        <Table.Column
          dataIndex='setFee'
          title='Set Fee'
          render={(_, record: any) => {
            return <span>{record.setFee}</span>;
          }}
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          render={(_, record: any) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <ShowButton hideText size='small' recordItemId={record.id} />
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
