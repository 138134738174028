import { useShow, IResourceComponentsProps } from '@pankod/refine-core';

import { Show, DateField, Typography } from '@pankod/refine-antd';

const { Title, Text } = Typography;

export const InventoryReceivedShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show title={<>{record?.name}</>} isLoading={isLoading}>
      <Title level={5}>Item Name</Title>
      <Text>{record?.itemId}</Text>

      <Title level={5}>Vendor Name</Title>
      <Text>{record?.vendor}</Text>

      <Title level={5}>Quantity</Title>
      <Text>{record?.quantity}</Text>
      <Title level={5}>Purchase Price</Title>
      <Text>{record?.purchasePrice}</Text>

      <Title level={5}>Invoice Number</Title>
      <Text>{record?.invoiceNumber}</Text>

      <Title level={5}>Received Date</Title>
      <Text>{<DateField format='LLL' value={record?.receiveDate} />}</Text>

      <Title level={5}>Expiry Date</Title>
      <Text>{<DateField format='LLL' value={record?.expireDate} />}</Text>
    </Show>
  );
};
