import {
  useTable,
  List,
  Table,
  EditButton,
  DeleteButton,
  Space,
} from '@pankod/refine-antd';

// types (TODO: remove these in favor of global types for main server types)
import { TAdministration } from '../../../types';

export const AdministrationUsersList = () => {
  const { tableProps } = useTable<TAdministration[]>();
  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column
          dataIndex='userName'
          title='Username'
          render={(_, record: TAdministration) => {
            return <span>{record.userName}</span>;
          }}
        />
        <Table.Column
          dataIndex='name'
          title='Name'
          render={(_, record: TAdministration) => {
            return <span>{record.displayName}</span>;
          }}
        />
        <Table.Column
          dataIndex='email'
          title='Email'
          render={(_, record: TAdministration) => {
            return <span>{record.email}</span>;
          }}
        />
        <Table.Column
          dataIndex='role'
          title='Role'
          render={(_, record: TAdministration) => {
            return <span>{record.role}</span>;
          }}
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          render={(_, record: any) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
