import { ResourceProps } from '@pankod/refine-core';
import { BillingPriceProfileCreate } from './create';
import { BillingPriceProfileEdit } from './edit';
import { BillingPriceProfileList } from './list';

export const billingPriceProfileResource: ResourceProps = {
  name: 'pricing-profiles',
  parentName: 'billing',
  options: {
    route: 'pricing-profiles',
    label: 'Price Profile',
  },
  create: BillingPriceProfileCreate,
  edit: BillingPriceProfileEdit,
  list: BillingPriceProfileList,
};
