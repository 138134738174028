import { useShow, IResourceComponentsProps } from '@pankod/refine-core';

import { Show, Typography } from '@pankod/refine-antd';

const { Title, Text } = Typography;

export const InventoryRequestShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show title={<>{record?.name}</>} isLoading={isLoading}>
      <Title level={5}>Item Name</Title>
      <Text>{record?.itemId}</Text>

      <Title level={5}>Department</Title>
      <Text>{record?.department}</Text>

      <Title level={5}>Quantity</Title>
      <Text>{record?.quantity}</Text>

      <Title level={5}>FulFilled</Title>
      <Text>{record?.isFulFilled ? 'Yes' : 'No'}</Text>
    </Show>
  );
};
