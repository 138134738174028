import React from 'react';

import generatePicker, {
  PickerProps,
} from 'antd/lib/date-picker/generatePicker';

import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export const HMISDatePicker: React.FC<PickerProps<Date>> = ({ ...props }) => {
  const date = props.value ? new Date(props.value) : null;
  const newProps = {
    ...props,
    value: date,
  };
  return <DatePicker {...newProps} />;
};
