import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  Form,
  Input,
  useForm,
  Select,
  Row,
  Col,
  InputNumber,
  Edit,
} from '@pankod/refine-antd';

export const BillingPricesEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label='Name'
              name='name'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Price'
              name='price'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Expense To'
              name='expenseTo'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Category'
              name='category'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: 'Imaging',
                    value: 'Imaging',
                  },
                  {
                    label: 'Lab',
                    value: 'Lab',
                  },
                  {
                    label: 'Procedure',
                    value: 'Procedure',
                  },
                  {
                    label: 'Ward',
                    value: 'Ward',
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label='Type'
              name='type'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
