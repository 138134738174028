import { IResourceComponentsProps } from '@pankod/refine-core';

import { Edit, Form, Input, useForm } from '@pankod/refine-antd';

import { HMISSelect } from '../../../components/core/HMISSelect';

export const ImagingEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, form } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item
          label='Patient Name'
          name='patientId'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <HMISSelect
            resource={{ module: 'patients' }}
            onChange={() => form.setFieldsValue({ visit: null })}
          />
        </Form.Item>
        <Form.Item
          shouldUpdate
          label='Visit'
          name='visit'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <HMISSelect
            showSearch
            optionFilterProp='label'
            resource={{
              module: 'visits',
              submodule: form.getFieldValue('patientId'),
            }}
          />
        </Form.Item>

        <Form.Item
          label='Requested By'
          name='requestedBy'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Type'
          name='type'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <HMISSelect
            resource={{ module: 'lookup', submodule: 'imaging-type' }}
          />
        </Form.Item>
        <Form.Item
          label='Result'
          name='result'
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Note'
          name='note'
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  );
};
