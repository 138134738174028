import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  Checkbox,
  Col,
  Edit,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  useForm,
} from '@pankod/refine-antd';
import { HMISSelect } from '../../../components/core/HMISSelect';
import { HMISDatePicker } from '../../../components/core/HMISDatePicker';

export const InventoryReceivedEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Item Name'
              name='name'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect resource={{ module: 'inventory' }} />
            </Form.Item>
            <Form.Item
              label='Invoice Number'
              name='invoiceNumber'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Quantity'
              name='quantity'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Date Received'
              name='recieveDate'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISDatePicker
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Status'
              name='status'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: 'Pending',
                    value: 'Pending',
                  },
                  {
                    label: 'Accepted',
                    value: 'Accepted',
                  },
                  {
                    label: 'Rejected',
                    value: 'Rejected',
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Vendor'
              name='vendor'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                resource={{ module: 'lookup', submodule: 'vendors' }}
              />
            </Form.Item>

            <Form.Item
              label='Purchase Price'
              name='purchasePrice'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Expiration Date'
              name='expireDate'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISDatePicker
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Received as Gift?'
              name='receivedAsGift'
              valuePropName='checked'
            >
              <Checkbox>Gift</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
