import {
  useTable,
  List,
  Table,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
  Space,
  Button,
} from '@pankod/refine-antd';
import { useNavigation } from '@pankod/refine-core';

import { TPatient } from '../../../types';

export const PatientList = () => {
  const navigate = useNavigation();
  const { tableProps } = useTable<TPatient[]>();
  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='ID' />

        <Table.Column
          dataIndex='name'
          title='Name'
          render={(_, record: TPatient) => {
            return (
              <span>
                {record.firstName} {record.middleName} {record.lastName}
              </span>
            );
          }}
        />

        <Table.Column
          dataIndex='dateOfBirth'
          title='Date of Birth'
          render={(_, record: TPatient) => {
            return (
              <span>
                <DateField format='LL' value={record?.dateOfBirth} />
              </span>
            );
          }}
        />

        <Table.Column
          dataIndex='gender'
          title='Gender'
          render={(_, record: TPatient) => {
            return <span>{record.gender}</span>;
          }}
        />

        <Table.Column
          dataIndex='patientStatus'
          title='Patient Status'
          render={(_, record: TPatient) => {
            return <span>{record.patientStatus}</span>;
          }}
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          render={(_, record: any) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <ShowButton hideText size='small' recordItemId={record.id} />
              <Button
                size='small'
                onClick={() => {
                  navigate.replace(`/patient/${record.id}/visits`);
                }}
              >
                Check In
              </Button>
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
