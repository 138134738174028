import { useShow, IResourceComponentsProps } from '@pankod/refine-core';

import { Show, DateField, Typography } from '@pankod/refine-antd';

const { Title, Text } = Typography;

export const ReturnMedicationShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      title={
        <>
          {record?.patient.firstName} {record?.patient.lastName} /{' '}
          {record?.item.name}
        </>
      }
      isLoading={isLoading}
    >
      <Title level={5}>Patient</Title>
      <Text>
        {record?.patient.firstName} {record?.patient.lastName}
      </Text>

      <Title level={5}>Medication</Title>
      <Text>{record?.item.name}</Text>

      <Title level={5}>Quantity To Return</Title>
      <Text>{record?.quantityToReturn}</Text>

      <Title level={5}>Return Location</Title>
      <Text>{record?.returnLocation}</Text>

      <Title level={5}>Reason To Return / Notes</Title>
      <Text>{record?.reasonToReturn}</Text>

      <Title level={5}>Credit To Account</Title>
      <Text>{record?.creditToAccount}</Text>

      <Title level={5}>Adjustment Date</Title>
      <Text>{<DateField format='LLL' value={record?.AdjustmentDate} />}</Text>
    </Show>
  );
};
