import {
  useTable,
  List,
  Table,
  ShowButton,
  Space,
  DateField,
} from '@pankod/refine-antd';

// types (TODO: remove these in favor of global types for main server types)
import { TMedicine } from '../../../types';

export const MedicationCompletedList = () => {
  const { tableProps } = useTable<TMedicine[]>();
  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='id' />
        <Table.Column
          dataIndex='Completed At'
          title='completedAt'
          render={(_, record: TMedicine) => {
            return <DateField format='LLL' value={record?.createdAt} />;
          }}
        />
        <Table.Column
          dataIndex='Patient'
          title='patient'
          render={(_, record: TMedicine) => {
            return (
              <span>
                {record.patient.firstName} {record.patient.lastName}
              </span>
            );
          }}
        />
        <Table.Column
          dataIndex='item'
          title='Medication'
          render={(_, record: TMedicine) => {
            return <span>{record.item.name}</span>;
          }}
        />
        <Table.Column
          dataIndex='quantity'
          title='Quantity'
          render={(_, record: TMedicine) => {
            return <span>{record.quantity}</span>;
          }}
        />
        <Table.Column
          dataIndex='isFulfilled'
          title='Status'
          render={() => {
            return <span>{'Fulfilled'}</span>;
          }}
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          render={(_, record: any) => (
            <Space>
              {/* <EditButton hideText size='small' recordItemId={record.id} /> */}
              <ShowButton hideText size='small' recordItemId={record.id} />
              {/* <DeleteButton hideText size='small' recordItemId={record.id} /> */}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
