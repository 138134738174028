import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  Checkbox,
  Col,
  Edit,
  Form,
  InputNumber,
  Row,
  useForm,
} from '@pankod/refine-antd';
import { HMISSelect } from '../../../components/core/HMISSelect';

export const InventoryRequestsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Item Name'
              name='itemId'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect resource={{ module: 'inventory' }} />
            </Form.Item>
            <Form.Item
              label='Quantity'
              name='quantity'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Department'
              name='department'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                resource={{ module: 'lookup', submodule: 'departments' }}
              />
            </Form.Item>
            <Form.Item
              label='Fulfilled'
              name='isFulFilled'
              valuePropName='checked'
            >
              <Checkbox>Fulfil Item</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
