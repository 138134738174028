import { IResourceComponentsProps } from '@pankod/refine-core';
import {
  Col,
  Create,
  Form,
  Input,
  useForm,
  Select,
  Row,
} from '@pankod/refine-antd';
import { HMISDatePicker } from '../../../components/core/HMISDatePicker';

export const PatientCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='First Name'
              name='firstName'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Middle Name'
              name='middleName'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Last Name'
              name='lastName'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Gender'
              name='gender'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                style={{
                  width: '100%',
                }}
                options={[
                  {
                    label: 'Male',
                    value: 'Male',
                  },
                  {
                    label: 'Female',
                    value: 'Female',
                  },
                  {
                    label: 'Others',
                    value: 'Others',
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label='Date of Birth(DOB)'
              name='dateOfBirth'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISDatePicker
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Religion'
              name='religion'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Occupation'
              name='occupation'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Patient Status'
              name='patientStatus'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Citizen'
              name='citizen'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                style={{
                  width: '100%',
                }}
                options={[
                  {
                    label: 'Nepali',
                    value: 'nepali',
                  },
                  {
                    label: 'Indian',
                    value: 'indian',
                  },
                  {
                    label: 'Others',
                    value: 'others',
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label='Blood Type'
              name='bloodType'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                style={{
                  width: '100%',
                }}
                options={[
                  {
                    label: 'A+',
                    value: 'A+',
                  },
                  {
                    label: 'A-',
                    value: 'A-',
                  },
                  {
                    label: 'B+',
                    value: 'B+',
                  },
                  {
                    label: 'B-',
                    value: 'B-',
                  },
                  {
                    label: 'AB+',
                    value: 'AB+',
                  },
                  {
                    label: 'AB-',
                    value: 'AB-',
                  },
                  {
                    label: 'O+',
                    value: 'O+',
                  },
                  {
                    label: 'O-',
                    value: 'O-',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
