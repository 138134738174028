import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  Create,
  Form,
  Input,
  useForm,
  Select,
  Row,
  Col,
} from '@pankod/refine-antd';
import { HMISDatePicker } from '../../../components/core/HMISDatePicker';

export const BillingCashierCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label='Bill Date'
              name='billDate'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <HMISDatePicker
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='Paitent'
              name='paitent'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='Visit'
              name='visit'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                style={{
                  width: '100%',
                }}
                options={[
                  {
                    label: '--Add New Outpatient Visit-- ',
                    value: '--Add New Outpatient Visit--',
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='External Invoice#'
              name='externalInvoice#'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='Payment Profile'
              name='paymentProfile'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: 'Option 1',
                    value: 'Option 1',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
