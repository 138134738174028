import { ResourceProps } from '@pankod/refine-core';
import { ImagingCreate } from './create';
import { ImagingEdit } from './edit';

import { ImagingList } from './list';

export const ImagingRequestResource: ResourceProps = {
  name: 'imagings/requests',
  parentName: 'imagings',
  options: {
    route: 'requests',
    label: 'Requests',
  },
  list: ImagingList,
  edit: ImagingEdit,
  create: ImagingCreate,
};
