import { ResourceProps } from '@pankod/refine-core';
import { AdministrationNewUserCreate } from './create';
import { AdministrationNewUserList } from './list';

export const administrationNewUserResource: ResourceProps = {
  name: 'administration/newUser',
  parentName: 'administration',
  options: {
    route: 'newUser/create',
    label: 'New User',
  },
  create: AdministrationNewUserCreate,
  list: AdministrationNewUserList,
};
