import {
  useTable,
  List,
  Table,
  DeleteButton,
  Space,
  Button,
} from '@pankod/refine-antd';

export const VisitList = ({ resource }) => {
  const { tableProps } = useTable({
    resource: `patients/${resource}/visits`,
  });
  return (
    <List title='Visit List' breadcrumb={false}>
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='ID' />

        <Table.Column
          dataIndex='name'
          title='Name'
          render={(_, record: any) => {
            return <span>{record.visitType}</span>;
          }}
        />

        <Table.Column
          title='Actions'
          dataIndex='actions'
          render={(_, record: any) => (
            <Space>
              <Button size='small' href={`visits/${record.id}`}>
                Edit
              </Button>
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
