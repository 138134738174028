import { useShow, IResourceComponentsProps } from '@pankod/refine-core';

import { Show, Typography } from '@pankod/refine-antd';

const { Title, Text } = Typography;

export const LabsNewRequestShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      title={
        <>
          {record?.firstName} {record?.lastName} / {record?.gender}
        </>
      }
      isLoading={isLoading}
    >
      <Title level={5}>Name</Title>
      <Text>
        {record?.firstName} {record?.lastName}
      </Text>

      <Title level={5}>Prescriber</Title>
      <Text>{record?.prescriber}</Text>

      <Title level={5}>Medication</Title>
      <Text>{record?.medication}</Text>
    </Show>
  );
};
