import { ResourceProps } from '@pankod/refine-core';
import { BillingNewInvoiceCreate } from './create';
import { BillingNewInvoiceList } from './list';
import { BillingNewInvoiceShow } from './show';

export const billingNewInvoiceResource: ResourceProps = {
  name: 'billings/invoices',
  parentName: 'billing',
  options: {
    route: 'invoices',
    label: 'Invoices',
  },
  create: BillingNewInvoiceCreate,
  list: BillingNewInvoiceList,
  show: BillingNewInvoiceShow,
};
