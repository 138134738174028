import { useShow, IResourceComponentsProps } from '@pankod/refine-core';
import { Show, DateField, Typography } from '@pankod/refine-antd';
import { useBarcode } from 'next-barcode';
const { Title, Text } = Typography;

export const PatientShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { inputRef } = useBarcode({
    value: record?.id as string,
    options: {
      height: 40,
      width: 1,
    },
  });

  return (
    <Show
      title={
        <>
          {record?.firstName} {record?.lastName} / {record?.gender}
        </>
      }
      isLoading={isLoading}
    >
      <svg ref={inputRef} />

      <Title level={5}>Name</Title>
      <Text>
        {record?.firstName} {record?.middleName} {record?.lastName}
      </Text>

      <Title level={5}>Gender</Title>
      <Text>{record?.gender}</Text>

      <Title level={5}>Date Of Birth</Title>
      <Text>{<DateField format='LL' value={record?.dateOfBirth} />}</Text>

      <Title level={5}>Citizen</Title>
      <Text>{record?.citizen}</Text>

      <Title level={5}>Blood Type</Title>
      <Text>{record?.bloodType}</Text>

      <Title level={5}>Religion</Title>
      <Text>{record?.religion}</Text>

      <Title level={5}>Occupation</Title>
      <Text>{record?.occupation}</Text>

      <Title level={5}>Patient Status</Title>
      <Text>{record?.patientStatus}</Text>
    </Show>
  );
};
