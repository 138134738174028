import { ResourceProps } from '@pankod/refine-core';
import { MedicationNewRequestCreate } from './create';
import { MedicationNewRequestEdit } from './edit';
import { MedicationNewRequestList } from './list';
import { MedicationNewRequestShow } from './show';

export const medicationNewRequestResource: ResourceProps = {
  name: 'medications/request',
  parentName: 'medication',
  options: {
    route: 'new-request',
    label: 'New Request',
  },
  create: MedicationNewRequestCreate,
  list: MedicationNewRequestList,
  show: MedicationNewRequestShow,
  edit: MedicationNewRequestEdit,
};
