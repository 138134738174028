import { ResourceProps } from '@pankod/refine-core';
import { PatientCreate } from './create';
import { PatientEdit } from './edit';

import { PatientList } from './list';
import { PatientShow } from './show';

export const patientListResource: ResourceProps = {
  name: 'patients/requests',
  parentName: 'patient',
  options: {
    route: 'patients-list',
    label: 'Patients List',
  },
  list: PatientList,
  edit: PatientEdit,
  show: PatientShow,
  create: PatientCreate,
};
