import { useShow, IResourceComponentsProps } from '@pankod/refine-core';

import { Show, DateField, Typography } from '@pankod/refine-antd';

const { Title, Text } = Typography;

export const IncidentShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show title={<> {record?.department} Department</>} isLoading={isLoading}>
      <Title level={5}>Date Of Incident</Title>
      <Text>{<DateField format='LLL' value={record?.incidentDate} />}</Text>

      <Title level={5}>Reported To</Title>
      <Text>{record?.reportedTo}</Text>

      <Title level={5}>Category</Title>
      <Text>{record?.category}</Text>

      <Title level={5}>Status</Title>
      <Text>{record?.status}</Text>

      <Title level={5}>Patient Impacted</Title>
      <Text>{record?.patientImpacted ? record?.patientImpacted : 'none'}</Text>

      <Title level={5}>Department</Title>
      <Text>{record?.department}</Text>

      <Title level={5}>Category Item</Title>
      <Text>{record?.categoryItem}</Text>

      <Title level={5}>Incident Description</Title>
      <Text>{record?.incidentDescription}</Text>

      <Title level={5}>Sentinel Event</Title>
      <Text>{record?.isSentinel ? 'Yes' : 'No'}</Text>
    </Show>
  );
};
