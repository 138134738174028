import {
  useTable,
  List,
  Table,
  EditButton,
  ShowButton,
  DeleteButton,
  Space,
} from '@pankod/refine-antd';
import { TInventoryRequest } from '../../../types';
export const InventoryRequestsList = () => {
  const { tableProps } = useTable<TInventoryRequest[]>();
  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='id' />

        <Table.Column
          dataIndex='name'
          title='Name'
          render={(_, record: TInventoryRequest) => {
            return <span>{record.item.name}</span>;
          }}
        />
        <Table.Column
          dataIndex='quantity'
          title='Quantity'
          render={(_, record: TInventoryRequest) => {
            return <span>{record.quantity}</span>;
          }}
        />
        <Table.Column
          dataIndex='department'
          title='Department'
          render={(_, record: TInventoryRequest) => {
            return <span>{record.department}</span>;
          }}
        />
        <Table.Column
          dataIndex='fulfilled'
          title='isFulfilled'
          render={(_, record: TInventoryRequest) => {
            return <span>{record.isFulFilled ? 'Yes' : 'No'}</span>;
          }}
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          render={(_, record: any) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <ShowButton hideText size='small' recordItemId={record.id} />
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
