import { ResourceProps } from '@pankod/refine-core';
import { LabsNewRequestCreate } from './create';
import { LabsNewRequestList } from './list';
import { LabsNewRequestShow } from './show';
import { LabsNewRequestEdit } from './edit';

export const labsNewRequestResource: ResourceProps = {
  name: 'labs/requests',
  parentName: 'labs',
  options: {
    route: 'requests',
    label: 'New Request',
  },
  create: LabsNewRequestCreate,
  list: LabsNewRequestList,
  show: LabsNewRequestShow,
  edit: LabsNewRequestEdit,
};
