import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  Edit,
  Form,
  Input,
  InputNumber,
  useForm,
  Col,
  Row,
} from '@pankod/refine-antd';

export const BillingPriceProfileEdit: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Name'
              name='name'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>

            <Form.Item
              label='Discount Percentage'
              name='discountPercentage'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Discount Amount'
              name='discountAmount'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Set Fee'
              name='setFee'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
