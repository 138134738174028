import { ResourceProps } from '@pankod/refine-core';
import { InventoryReceivedCreate } from './create';
import { InventoryReceivedEdit } from './edit';
import { InventoryReceivedList } from './list';
import { InventoryReceivedShow } from './show';

export const inventoryReceivedResources: ResourceProps = {
  name: 'inventory/received',
  parentName: 'inventory',
  options: {
    route: 'received',
    label: 'Received',
  },
  list: InventoryReceivedList,
  create: InventoryReceivedCreate,
  edit: InventoryReceivedEdit,
  show: InventoryReceivedShow,
};
