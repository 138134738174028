import { useTranslation } from 'react-i18next';

export const useHMISi18nProvider = () => {
  const { t, i18n } = useTranslation();

  const provider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return provider;
};
