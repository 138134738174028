import { ResourceProps } from '@pankod/refine-core';
import { PatientOutPatientsList } from './list';

export const patientOutPatientsResource: ResourceProps = {
  name: 'patients/visit/outpatients',
  parentName: 'patient',
  options: {
    route: 'outpatients',
    label: 'Out Patients',
  },
  list: PatientOutPatientsList,
};
