import { IResourceComponentsProps } from '@pankod/refine-core';
import {
  Col,
  Edit,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  useForm,
} from '@pankod/refine-antd';
import { HMISSelect } from '../../../components/core/HMISSelect';
import { HMISDatePicker } from '../../../components/core/HMISDatePicker';

export const MedicationDispenseEdit: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, saveButtonProps, form } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Patient'
              name='patientId'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                resource={{ module: 'patients' }}
                onChange={() => form.setFieldsValue({ visit: null })}
              />
            </Form.Item>
            <Form.Item
              label='Medication'
              name='itemId'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                resource={{ module: 'inventory', submodule: 'medications' }}
              />
            </Form.Item>
            <Form.Item
              label='Prescription'
              name='prescription'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{
                  height: '100px',
                  width: '200%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Prescription Date'
              name='prescriptionDate'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <HMISDatePicker
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Row gutter={16}>
              <Col>
                <Form.Item
                  label='Quantity Dispensed'
                  name='quantityToDispense'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label='Refills'
                  name='refills'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label='Bill To'
              name='billTo'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{
                  width: '200%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Visit'
              name='visit'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                showSearch
                optionFilterProp='label'
                resource={{
                  module: 'visits',
                  submodule: form.getFieldValue('patientId'),
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
