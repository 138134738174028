import { useUpdate, useInvalidate } from '@pankod/refine-core';
export const useHandleDischarge = () => {
  const { mutate } = useUpdate();
  const invalidate = useInvalidate();
  return function discharge(patientId: string) {
    mutate({
      resource: `patients/${patientId}/visits/discharge`,
      id: patientId,
      values: { isCheckedin: false },
      invalidates: ['all'],
    });
  };
};
