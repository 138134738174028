import { Authenticated, useRouterContext } from '@pankod/refine-core';
import { VisitEdit } from '../edit';

export const EditPatientVisit = () => {
  const { useParams } = useRouterContext();
  const { id, visitId } = useParams<{ id: string; visitId: string }>();
  return (
    <Authenticated>
      <>
        <VisitEdit resource={`patients/${id}/visits/${visitId}`} />
      </>
    </Authenticated>
  );
};
