// refine
import { Refine } from '@pankod/refine-core';

// providers
import {
  hmisAuthProvider,
  hmisDataProvider,
  hmisRouterProvider,
  hmisNotificationProvider,
  HMISAuditLogProvider,
  useHMISi18nProvider,
} from './providers';
import { RefineKbarProvider } from '@pankod/refine-kbar';

// resources
import { resources } from './resources';
import { Signup } from './pages/auth/signup';
// components
import {
  HMISErrorComponent,
  HMISHeader,
  HMISLayoutComponent,
  HMISTitle,
  OffLayoutArea,
} from './components/core';

import { HMISLoginPage } from './pages/auth/login';
import {
  EditPatientVisit,
  PatientVisit,
} from './pages/patients/visit/patient-visit';

import '@pankod/refine-antd/dist/reset.css';

export default function HospitalManagementSystemApplication() {
  const i18nProvider = useHMISi18nProvider();

  return (
    <RefineKbarProvider>
      <Refine
        authProvider={hmisAuthProvider}
        dataProvider={hmisDataProvider}
        routerProvider={{
          ...hmisRouterProvider,
          routes: [
            {
              path: '/patient/:id/visits',
              element: <PatientVisit />,
              layout: true,
            },
            {
              path: '/patient/:id/visits/:visitId',
              element: <EditPatientVisit />,
              layout: true,
            },
            {
              path: 'auth/signup',
              element: <Signup />,
              layout: false,
            },
          ],
        }}
        notificationProvider={hmisNotificationProvider}
        resources={resources}
        i18nProvider={i18nProvider}
        auditLogProvider={HMISAuditLogProvider}
        options={{
          syncWithLocation: true,
          warnWhenUnsavedChanges: true,
          reactQuery: {
            clientConfig: {
              defaultOptions: {
                queries: {
                  refetchOnWindowFocus: false,
                },
              },
            },
          },
        }}
        // overriding custom elements/components
        // DashboardPage={() => <p>Here will be visualizations</p>}
        Title={HMISTitle}
        Header={HMISHeader}
        Layout={HMISLayoutComponent}
        LoginPage={() => (
          <HMISLoginPage
            registerLink={false}
            renderContent={(content: React.ReactNode) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src='/images/hospitalsign.png'
                    alt='HMIS logo'
                    style={{
                      width: '80px',
                      height: '80px',
                      marginBottom: '16px',
                    }}
                  />
                  {content}
                </div>
              );
            }}
          />
        )}
        OffLayoutArea={OffLayoutArea}
        catchAll={<HMISErrorComponent />}
      />
    </RefineKbarProvider>
  );
}
