import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  Create,
  Form,
  Input,
  useForm,
  Select,
  Row,
  Col,
  useTable,
  List,
  Table,
  EditButton,
  ShowButton,
  DeleteButton,
  Space,
} from '@pankod/refine-antd';
import { HMISDatePicker } from '../../../components/core/HMISDatePicker';

import { TBilling, TLineItems } from '../../../types';

export const BillingNewInvoiceCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, saveButtonProps } = useForm();
  const { tableProps } = useTable<TBilling[]>();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label='Bill Date'
              name='billDate'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <HMISDatePicker
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='Paitent'
              name='paitent'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='Visit'
              name='visit'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                style={{
                  width: '100%',
                }}
                options={[
                  {
                    label: '--Add New Outpatient Visit-- ',
                    value: '--Add New Outpatient Visit--',
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='External Invoice#'
              name='externalInvoice#'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='Payment Profile'
              name='paymentProfile'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: 'Option 1',
                    value: 'Option 1',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <List>
          <Table {...tableProps} rowKey='id'>
            <Table.Column
              dataIndex='description'
              title='Description'
              render={(_, record: TLineItems) => {
                return <span>{record.label}</span>;
              }}
            />
            <Table.Column
              dataIndex='name'
              title='Name'
              render={(_, record: TBilling) => {
                return <span>{record.name}</span>;
              }}
            />
            <Table.Column
              dataIndex='actualCharges'
              title='Actual Charges'
              render={(_, record: TBilling) => {
                return <span>{record.name}</span>;
              }}
            />
            <Table.Column
              dataIndex='discount'
              title='Discount'
              render={(_, record: TBilling) => {
                return <span>{record.name}</span>;
              }}
            />
            <Table.Column
              dataIndex='nationalInsurance'
              title='National Insurance'
              render={(_, record: TBilling) => {
                return <span>{record.name}</span>;
              }}
            />
            <Table.Column
              dataIndex='hmoCom'
              title='HOM/COM'
              render={(_, record: TBilling) => {
                return <span>{record.name}</span>;
              }}
            />
            <Table.Column
              dataIndex='excess'
              title='Excess'
              render={(_, record: TBilling) => {
                return <span>{record.name}</span>;
              }}
            />
            <Table.Column
              title='Actions'
              dataIndex='actions'
              render={(_, record: any) => (
                <Space>
                  <EditButton hideText size='small' recordItemId={record.id} />
                  <ShowButton hideText size='small' recordItemId={record.id} />
                  <DeleteButton
                    hideText
                    size='small'
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
        <List>
          <Table {...tableProps} rowKey='id'>
            <Table.Column
              dataIndex='date'
              title='Date'
              render={(_, record: TLineItems) => {
                return <span>{record.label}</span>;
              }}
            />
            <Table.Column
              dataIndex='name'
              title='Name'
              render={(_, record: TBilling) => {
                return <span>{record.name}</span>;
              }}
            />
            <Table.Column
              dataIndex='actualCharges'
              title='Actual Charges'
              render={(_, record: TBilling) => {
                return <span>{record.name}</span>;
              }}
            />
            <Table.Column
              dataIndex='discount'
              title='Discount'
              render={(_, record: TBilling) => {
                return <span>{record.name}</span>;
              }}
            />
            <Table.Column
              dataIndex='nationalInsurance'
              title='National Insurance'
              render={(_, record: TBilling) => {
                return <span>{record.name}</span>;
              }}
            />
            <Table.Column
              dataIndex='hmoCom'
              title='HOM/COM'
              render={(_, record: TBilling) => {
                return <span>{record.name}</span>;
              }}
            />
            <Table.Column
              dataIndex='excess'
              title='Excess'
              render={(_, record: TBilling) => {
                return <span>{record.name}</span>;
              }}
            />
            <Table.Column
              title='Actions'
              dataIndex='actions'
              render={(_, record: any) => (
                <Space>
                  <EditButton hideText size='small' recordItemId={record.id} />
                  <ShowButton hideText size='small' recordItemId={record.id} />
                  <DeleteButton
                    hideText
                    size='small'
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
      </Form>
    </Create>
  );
};
