import {
  useTable,
  List,
  Table,
  EditButton,
  ShowButton,
  DeleteButton,
  Space,
} from '@pankod/refine-antd';

// types (TODO: remove these in favor of global types for main server types)
import { TLabs } from '../../../types';

export const LabsCompletedList = () => {
  const { tableProps } = useTable<TLabs[]>();
  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column
          dataIndex='dateRequested'
          title='Date Requested'
          render={(_, record: TLabs) => {
            return <span>{record.dateRequested}</span>;
          }}
        />
        <Table.Column
          dataIndex='dateCompleted'
          title='Date Completed'
          render={(_, record: TLabs) => {
            return <span>{record.dateCompleted}</span>;
          }}
        />
        <Table.Column
          dataIndex='paitent'
          title='Paitent'
          render={(_, record: TLabs) => {
            return <span>{record.patient.firstName}</span>;
          }}
        />
        <Table.Column
          dataIndex='requestedBy'
          title='Requested By'
          render={(_, record: TLabs) => {
            return <span>{record.requestedBy}</span>;
          }}
        />
        <Table.Column
          dataIndex='labType'
          title='Lab Type'
          render={(_, record: TLabs) => {
            return <span>{record.labType}</span>;
          }}
        />
        <Table.Column
          dataIndex='results'
          title='Results'
          render={(_, record: TLabs) => {
            return <span>{record.result}</span>;
          }}
        />

        <Table.Column
          dataIndex='notes'
          title='Notes'
          render={(_, record: TLabs) => {
            return <span>{record.note}</span>;
          }}
        />
      </Table>
    </List>
  );
};
