import {
  useTable,
  List,
  Table,
  EditButton,
  ShowButton,
  DeleteButton,
  Space,
  Tabs,
} from '@pankod/refine-antd';

// types (TODO: remove these in favor of global types for main server types)
import { TPricingItem } from '../../../types';

interface PropType {
  resource: string;
}
const PricesTable: React.FC<PropType> = ({ resource }) => {
  const { tableProps } = useTable<TPricingItem[]>();
  return (
    <Table {...tableProps} rowKey='id'>
      <Table.Column dataIndex='id' title='id' />
      <Table.Column
        dataIndex='category'
        title='Category'
        render={(_, record: TPricingItem) => {
          return <span>{record.category}</span>;
        }}
      />
      <Table.Column
        dataIndex='name'
        title='Name'
        render={(_, record: TPricingItem) => {
          return <span>{record.name}</span>;
        }}
      />
      <Table.Column
        dataIndex='price'
        title='Price'
        render={(_, record: TPricingItem) => {
          return <span>{record.price}</span>;
        }}
      />
      <Table.Column
        dataIndex='type'
        title='Type'
        render={(_, record: TPricingItem) => {
          return <span>{record.type}</span>;
        }}
      />
      <Table.Column
        dataIndex='expenseTo'
        title='Expense To'
        render={(_, record: TPricingItem) => {
          return <span>{record.expenseTo}</span>;
        }}
      />
      <Table.Column
        title='Actions'
        dataIndex='actions'
        render={(_, record: any) => (
          <Space>
            <EditButton hideText size='small' recordItemId={record.id} />
            <ShowButton hideText size='small' recordItemId={record.id} />
            <DeleteButton hideText size='small' recordItemId={record.id} />
          </Space>
        )}
      />
    </Table>
  );
};

export const BillingPricesList = () => {
  return (
    <List>
      <Tabs>
        <Tabs.TabPane tab='All Items' key='all'>
          <PricesTable resource='' />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Imaging Pricing' key='imagings'>
          <PricesTable resource='imagings' />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Lab Pricing' key='labs'>
          <PricesTable resource='labs' />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Procedure Pricing' key='procedures'>
          <PricesTable resource='procedures' />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Ward Pricing' key='wards'>
          <PricesTable resource='wards' />
        </Tabs.TabPane>
      </Tabs>
    </List>
  );
};
