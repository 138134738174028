import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  Create,
  Form,
  DatePicker,
  useForm,
  Select,
  Row,
  Col,
  Button,
} from '@pankod/refine-antd';

export const PatientReport: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Report Type'
              name='reportType'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: 'Admissions Detail',
                    value: 'AdmissionsDetail',
                  },
                  {
                    label: 'Admissions Summary',
                    value: 'admissionsSummary',
                  },
                  {
                    label: 'Diagnostic Testing',
                    value: 'diagnosticTesting',
                  },
                  {
                    label: 'Diagnostic Testing',
                    value: 'diagnosticTesting',
                  },
                  {
                    label: 'Diagnostic Detail',
                    value: 'diagnosticDetail',
                  },
                  {
                    label: 'Discharges Detail',
                    value: 'dischargesDetail',
                  },
                  {
                    label: 'Discharges Summary',
                    value: 'dischargesSummary',
                  },
                  {
                    label: 'Procedures Detail',
                    value: 'proceduresDetail',
                  },
                  {
                    label: 'Procedures Summary',
                    value: 'proceduresSummary',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label='Start Date'
              name='startDate'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <DatePicker
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='End Date'
              name='endDate'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <DatePicker
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Button type='primary'>Generate Report</Button>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
