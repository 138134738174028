import { IResourceComponentsProps } from '@pankod/refine-core';
import { HMISSelect } from '../../../components/core/HMISSelect';

import {
  Create,
  Form,
  Input,
  useForm,
  Row,
  Col,
  InputNumber,
} from '@pankod/refine-antd';
import { HMISDatePicker } from '../../../components/core/HMISDatePicker';

export const ReturnMedicationCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, saveButtonProps, form } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Medication'
              name='itemId'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                resource={{ module: 'inventory', submodule: 'medications' }}
              />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label='Patient'
                  name='patientId'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <HMISSelect
                    resource={{ module: 'patients' }}
                    onChange={() => form.setFieldsValue({ visit: null })}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Visit'
                  name='visit'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <HMISSelect
                    showSearch
                    optionFilterProp='label'
                    resource={{
                      module: 'visits',
                      submodule: form.getFieldValue('patientId'),
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item
                  label='Quantity To Return'
                  name='quantityToReturn'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    style={{
                      width: '50%',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='Return Location'
                  name='returnLocation'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label='Return Reason/Notes'
              name='reasonToReturn'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                style={{
                  height: '100px',
                  width: '200%',
                }}
              />
            </Form.Item>
            <Row gutter={16}>
              <Col>
                <Form.Item
                  label='AdjustmentDate'
                  name='AdjustmentDate'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <HMISDatePicker
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label='Credit To Account'
                  name='creditToAccount'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: '200%',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
