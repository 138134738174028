import { ResourceProps } from '@pankod/refine-core';

import { MedicationCompletedList } from './list';
import { CompletedMedicationShow } from './show';

export const medicationCompletedResource: ResourceProps = {
  name: 'medications/completed',
  parentName: 'medication',
  options: {
    route: 'completed',
    label: 'completed',
  },
  list: MedicationCompletedList,
  show: CompletedMedicationShow,
};
