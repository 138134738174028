import {
  useTable,
  List,
  Table,
  EditButton,
  ShowButton,
  DeleteButton,
  Space,
} from '@pankod/refine-antd';

import { TInventoryReceived } from '../../../types';

export const InventoryReceivedList = () => {
  const { tableProps } = useTable<TInventoryReceived[]>();

  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='id' />
        <Table.Column
          dataIndex='invoiceNumber'
          title='Invoice number'
          render={(_, record: TInventoryReceived) => {
            return <span>{record.invoiceNumber}</span>;
          }}
        />
        <Table.Column
          dataIndex='vendor'
          title='Vendor'
          render={(_, record: TInventoryReceived) => {
            return <span>{record.vendor}</span>;
          }}
        />
        <Table.Column
          dataIndex='quantity'
          title='quantity'
          render={(_, record: TInventoryReceived) => {
            return <span>{record.quantity}</span>;
          }}
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          render={(_, record: any) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <ShowButton hideText size='small' recordItemId={record.id} />
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
