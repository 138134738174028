import { ResourceProps } from '@pankod/refine-core';
import { InventoryRequestsCreate } from './create';
import { InventoryRequestsEdit } from './edit';
import { InventoryRequestsList } from './list';
import { InventoryRequestShow } from './show';

export const inventoryRequestsResources: ResourceProps = {
  name: 'inventory/requests',
  parentName: 'inventory',
  options: {
    route: 'requests',
    label: 'Requests',
  },
  list: InventoryRequestsList,
  create: InventoryRequestsCreate,
  edit: InventoryRequestsEdit,
  show: InventoryRequestShow,
};
