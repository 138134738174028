import { ResourceProps } from '@pankod/refine-core';
import { AdministrationUsersList } from './list';

export const administrationUsersListResource: ResourceProps = {
  name: 'administration/users',
  parentName: 'administration',
  options: {
    route: 'users/list',
    label: 'Users',
  },
  list: AdministrationUsersList,
};
