import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  Button,
  Col,
  Edit,
  Form,
  Input,
  Row,
  useForm,
} from '@pankod/refine-antd';
import { MinusCircleOutlined } from '@ant-design/icons';

export const LookupEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Lookup Type'
              name='type'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.List
              name='values'
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error('At least one value is needed'),
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      label={index === 0 ? 'Lookup Values' : ''}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input lookup value here',
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder='Enter Value'
                          style={{ width: '90%' }}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        style={{
                          width: '10%',
                        }}
                        onClick={() => remove(field.name)}
                      />
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => add()}
                      style={{ width: '100%' }}
                    >
                      Add field
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
