import {
  Edit,
  Form,
  Col,
  Row,
  Input,
  Select,
  useForm,
} from '@pankod/refine-antd';
import { HMISDatePicker } from '../../../components/core/HMISDatePicker';

export const VisitEdit = ({ resource }) => {
  const { formProps, saveButtonProps } = useForm({
    resource: resource,
  });

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      title='Edit Patient Visit'
      resource={resource}
    >
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label='Admission Date'
              name='visitDate'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISDatePicker
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Visit'
              name='visitType'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: 'Admission',
                    value: 'Admission',
                  },
                  {
                    label: 'Clinic',
                    value: 'Clinic',
                  },
                  {
                    label: 'Followup',
                    value: 'Followup',
                  },
                  {
                    label: 'Imaging',
                    value: 'Imaging',
                  },
                  {
                    label: 'Lab',
                    value: 'Lab',
                  },
                  {
                    label: 'Pharmacy',
                    value: 'Pharmacy',
                  },
                  {
                    label: 'Emergency',
                    value: 'Emergency',
                  },
                ]}
              />
            </Form.Item>
            <Form.Item label='Reason For Visit' name='reasonForVisit'>
              <Input
                style={{
                  height: '100px',
                  width: '200%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Discharge Date' name='dischargeDate'>
              <HMISDatePicker
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item label='Examiner' name='examiner'>
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span='8'>
            <Form.Item label='Location' name='location'>
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
