import { IResourceComponentsProps } from '@pankod/refine-core';

import { Create, Form, Input, useForm, Row, Col } from '@pankod/refine-antd';
import { HMISSelect } from '../../../components/core';

export const LabsNewRequestCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, form } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} {...form} layout='vertical'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Patient Name'
              name='patientId'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                resource={{ module: 'patients' }}
                onChange={() => form.setFieldsValue({ visit: null })}
              />
            </Form.Item>
            <Form.Item
              shouldUpdate
              label='Visit'
              name='visit'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                showSearch
                optionFilterProp='label'
                resource={{
                  module: 'visits',
                  submodule: form.getFieldValue('patientId'),
                }}
              />
            </Form.Item>
            <Form.Item
              label='Requested By'
              name='requestedBy'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>

            <Form.Item
              label='Lab Type'
              name='type'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <HMISSelect
                resource={{ module: 'lookup', submodule: 'lab-type' }}
                options={[{ label: 'Blood', value: 'blood' }]}
              />
            </Form.Item>

            <Form.Item
              label='Result'
              name='result'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Notes'
              name='note'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
