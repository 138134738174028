import { ResourceProps } from '@pankod/refine-core';

import { LabsCompletedList } from './list';

export const labsCompletedResource: ResourceProps = {
  name: 'labs/completed',
  parentName: 'labs',
  options: {
    route: 'completed',
    label: 'Completed',
  },
  list: LabsCompletedList,
};
