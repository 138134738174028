export const HMISTitle = ({ collapsed }: { collapsed: boolean }) => {
  return (
    <section
      style={{
        fontSize: collapsed ? 16 : 24,
        margin: 16,
        color: 'white',
        textAlign: 'center',
      }}
    >
      HMIS
    </section>
  );
};
