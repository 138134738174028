import { ResourceProps } from '@pankod/refine-core';
import { BillingPricesCreate } from './create';
import { BillingPricesEdit } from './edit';

import { BillingPricesList } from './list';

export const billingPricesResource: ResourceProps = {
  name: 'pricings',
  parentName: 'billing',
  options: {
    route: 'prices',
    label: 'Prices',
  },
  list: BillingPricesList,
  create: BillingPricesCreate,
  edit: BillingPricesEdit,
};
