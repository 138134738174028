import { ResourceProps } from '@pankod/refine-core';

import { IncidentList } from './list';
import { IncidentShow } from './show';
import { IncidentEdit } from './edit';
import { IncidentCreate } from './create';

export const NewIncidentResource: ResourceProps = {
  name: 'incidents/requests',
  parentName: 'incidents',
  options: {
    route: 'incidents/requests',
    label: 'New Incident',
  },
  create: IncidentCreate,
  list: IncidentList,
  show: IncidentShow,
  edit: IncidentEdit,
};
