import { ResourceProps } from '@pankod/refine-core';
import { LookupCreate } from './create';
import { LookupEdit } from './edit';
import { LookupList } from './list';
import { MdOutlineScreenSearchDesktop } from 'react-icons/md';

export const lookupResource: ResourceProps = {
  name: 'lookup',
  list: LookupList,
  create: LookupCreate,
  edit: LookupEdit,
  icon: <MdOutlineScreenSearchDesktop />,
};
