import { useShow, IResourceComponentsProps } from '@pankod/refine-core';

import { Show, DateField, Typography } from '@pankod/refine-antd';

const { Title, Text } = Typography;

export const MedicationDispenseShow: React.FC<
  IResourceComponentsProps
> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      title={
        <>
          {record?.patient.firstName} {record?.patient.lastName} /{' '}
          {record?.item.name}
        </>
      }
      isLoading={isLoading}
    >
      <Title level={5}>Patient</Title>
      <Text>
        {record?.patient.firstName} {record?.patient.lastName}
      </Text>

      <Title level={5}>Medication</Title>
      <Text>{record?.item.name}</Text>

      <Title level={5}>Prescription</Title>
      <Text>{record?.prescription}</Text>

      <Title level={5}>Prescription Date</Title>
      <Text>{<DateField format='LLL' value={record?.prescriptionDate} />}</Text>

      <Title level={5}>Quantity To Dispense </Title>
      <Text>{record?.quantityToDispense}</Text>

      <Title level={5}>Bill To</Title>
      <Text>{record?.billTo}</Text>

      <Title level={5}>Refills</Title>
      <Text>{record?.refills ? record?.refills : 'none'}</Text>
    </Show>
  );
};
