import React from 'react';

import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  useForm,
  Create,
} from '@pankod/refine-antd';

const { Title } = Typography;

export const Signup: React.FC = () => {
  const CardTitle = (
    <Title level={3} className='title'>
      HMIS - Sign up your account
    </Title>
  );

  const { formProps, form, saveButtonProps } = useForm();

  return (
    <AntdLayout className='layout'>
      <Row
        justify='center'
        align='middle'
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div className='container'>
            <Card headStyle={{ borderBottom: 0 }}>
              <Create saveButtonProps={saveButtonProps} title={CardTitle}>
                <Form {...formProps} {...form} layout='vertical'>
                  <Form.Item
                    label='Email'
                    name='email'
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: '100%',
                      }}
                      placeholder={'example@example.com'}
                    />
                  </Form.Item>
                  <Form.Item
                    label='Password'
                    name='password'
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: '100%',
                      }}
                      type='password'
                      placeholder={'*********'}
                    />
                  </Form.Item>
                  <Form.Item
                    name='role'
                    initialValue={'admin'}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input type='hidden' />
                  </Form.Item>
                </Form>
              </Create>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
