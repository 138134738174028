import { ResourceProps } from '@pankod/refine-core';
import { BillingCashierCreate } from './create';
import { BillingCashierList } from './list';
import { BillingCashierShow } from './show';

export const billingCashierResource: ResourceProps = {
  name: 'billings/invoices',
  parentName: 'billing',
  options: {
    route: 'Cashier',
    label: 'Cashier',
  },
  create: BillingCashierCreate,
  list: BillingCashierList,
  show: BillingCashierShow,
};
