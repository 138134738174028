import {
  useTable,
  List,
  Table,
  EditButton,
  DeleteButton,
  Space,
} from '@pankod/refine-antd';

import { TLookup } from '../../types';

export const LookupList = () => {
  const { tableProps } = useTable<TLookup[]>();

  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='id' />
        <Table.Column
          dataIndex='type'
          title='Type'
          render={(_, record: TLookup) => {
            return <span>{record.type}</span>;
          }}
        />

        <Table.Column
          title='Actions'
          dataIndex='actions'
          render={(_, record: any) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
