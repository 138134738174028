import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from '@pankod/refine-antd';
import { useGetOptions } from '../../services/hmisselect.services';

interface PropType {
  resource: TSelectResourceType;
}

export type TSelectResourceType = {
  module: string;
  submodule?: string;
};

export const HMISSelect: React.FC<PropType & SelectProps> = ({
  resource,
  ...props
}) => {
  const [value, setValue] = useState<string | number>();
  useEffect(() => {
    if (value === undefined && props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <Select
      showSearch={true}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={(value) => {
        if (value) setValue(value);
      }}
      options={useGetOptions(resource, value)}
      {...props}
    />
  );
};
