import { ResourceProps } from '@pankod/refine-core';

import { InventoryItemsCreate } from './create';
import { InventoryItemsEdit } from './edit';
import { InventoryItemsList } from './list';
import { InventoryItemsShow } from './show';

export const inventoryItemResources: ResourceProps = {
  name: 'inventory/items',
  parentName: 'inventory',
  options: {
    route: 'items',
    label: 'Items',
  },
  list: InventoryItemsList,
  create: InventoryItemsCreate,
  edit: InventoryItemsEdit,
  show: InventoryItemsShow,
};
