import { ResourceProps } from '@pankod/refine-core';
import { ReturnMedicationCreate } from './create';
import { ReturnMedicationEdit } from './edit';
import { ReturnMedicationList } from './list';
import { ReturnMedicationShow } from './show';

export const returnMedicationResource: ResourceProps = {
  name: 'return-medications',
  parentName: 'medication',
  options: {
    route: 'return-medication',
    label: 'Return Medication',
  },
  create: ReturnMedicationCreate,
  list: ReturnMedicationList,
  show: ReturnMedicationShow,
  edit: ReturnMedicationEdit,
};
