import { VisitList } from '../list';
import { VisitCreate } from '../create';
import { Authenticated, useRouterContext } from '@pankod/refine-core';

export const PatientVisit = () => {
  const { useParams } = useRouterContext();
  const { id } = useParams<{ id: string }>();
  return (
    <Authenticated>
      <>
        <VisitCreate resource={id} />
        <VisitList resource={id} />
      </>
    </Authenticated>
  );
};
