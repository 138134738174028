import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  Create,
  Form,
  Input,
  useForm,
  Select,
  Row,
  Col,
} from '@pankod/refine-antd';

export const AdministrationNewUserCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Display Name'
              name='displayName'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                style={{
                  width: '200%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='User Name'
              name='userName'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                style={{
                  width: '200%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Email'
              name='email'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Password'
              name='password'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.Password
                style={{
                  width: '200%',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Role'
              name='role'
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                style={{
                  width: '200%',
                }}
                options={[
                  {
                    label: 'Data Entry',
                    name: 'dataEntry',
                  },
                  {
                    label: 'Doctor',
                    name: 'doctor',
                  },
                  {
                    label: 'Finance',
                    name: 'finance',
                  },
                  {
                    label: 'Nurse',
                    name: 'nurse',
                  },
                  {
                    label: 'Cashier',
                    name: 'cashier',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
