import { ResourceProps } from '@pankod/refine-core';
import { PaitentAdmitPatientsList } from './list';

export const paitentAdmitPatientsResource: ResourceProps = {
  name: 'patients/visit/inpatients',
  parentName: 'patient',
  options: {
    route: 'admitted',
    label: 'Admitted Patients',
  },
  list: PaitentAdmitPatientsList,
};
