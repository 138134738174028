import {
  useTable,
  List,
  Table,
  EditButton,
  ShowButton,
  DeleteButton,
  Space,
  DateField,
} from '@pankod/refine-antd';

// types (TODO: remove these in favor of global types for main server types)
import { TIncident } from '../../../types';

export const IncidentList = () => {
  const { tableProps } = useTable<TIncident[]>();
  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='id' />
        <Table.Column
          dataIndex='department'
          title='Department'
          render={(_, record: TIncident) => {
            return <span>{record.department}</span>;
          }}
        />
        <Table.Column
          dataIndex='incidentDate'
          title='Incident Date'
          render={(_, record: TIncident) => {
            return (
              <span>
                <DateField format='LLL' value={record?.incidentDate} />
              </span>
            );
          }}
        />

        <Table.Column
          dataIndex='reportedTo'
          title='Reported To'
          render={(_, record: TIncident) => {
            return <span>{record.reportedTo}</span>;
          }}
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          render={(_, record: any) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <ShowButton hideText size='small' recordItemId={record.id} />
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
